<template>
    <div class="admin-container">
        <h1>Webinars</h1>
        <div class="table-container">
            <div class="table-filter">
                <div :class="['btn-form', {active : display == 1}]" @click="display = 1">Planifiés</div>
                <div :class="['btn-form', {active : display == 2}]" @click="display = 2">Terminés</div>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nom</th>
                        <th>Topic</th>
                        <th>Intervenant</th>
                        <th>Date début</th>
                        <th>Date fin</th>
                        <th>Participants</th>
                        <th>Liens inscription</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, idx) in webinarList" :key="idx">
                        <td>{{item.number}}</td>
                        <td>{{item.name}}</td>
                        <td v-html="item.topic"></td>
                        <td>{{ `${item.speaker_title} ${item.firstname} ${item.lastname}`}}</td>
                        <td class="date-display">{{displayDate(item.date_start)}}</td>
                        <td class="date-display">{{displayDate(item.date_end)}}</td>
                        <td>{{item.participant}}</td>
                        <td>
                            <a class="link-register" :href="`https://webinar.backbone.pro/register/${item.webinar_id}`" target="_blank">{{`https://webinar.backbone.pro/register/${item.webinar_id}`}}</a>
                        </td>
                        <td>
                            <div class="actions-container">
                                <router-link :to="`/admin/users-list/${item.webinar_id}`" class="actions-btn users-btn" v-if="item.participant > 0"></router-link>
                                <router-link :to="`/admin/webinar-questions/${item.webinar_id}`" class="actions-btn questions-btn"></router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <NotifError :errorTxt="errorTxt" v-if="errorTxt" v-on:closeModal="errorTxt = null"></NotifError>
    </div>
</template>

<script>
import axios from 'axios'
import store from './../../store'
import NotifError from './../../components/NotifError'

export default {
    name :'AdminWebinarList',
    components : {
        NotifError
    },
    data() {
        return {
            webinar : null,
            display : 1,
            errorTxt:null
        }
    },
    mounted() {
        let vm = this

        this.errorTxt = null
        
        axios
            .get(store.getters.getURL + "webinars/getAllWebinars.php?token=" + store.state.JWT_TOKEN)
            .then(function (response) { 
                if(response.data.state == 'success') {
                    vm.webinar = response.data.res
                }else {
                    if(response.data.authError) {
                        store.commit('logout')
                        vm.$router.push('/login')
                    }else {
                        vm.errorTxt = 'An error has occured: ' + response.data.retour
                    }
                }
            })
            .catch((err) => {
                this.errorTxt = 'An error has occured: ' +  err
            });
    },
    methods : {
        displayDate(item) {
            const date = new Date(item);
            const formattedDate = date.toLocaleString("fr-FR", {
                day: "numeric",
                month: "short",
                year: "numeric",
                hour: "numeric",
                minute: "2-digit"
            });

            return formattedDate;
        }
    }, 
    computed : {
        webinarList() {
            if(this.webinar) {
                if(this.display === 1) {
                    return this.webinar.filter(e => e.available)
                }else if (this.display === 2) {
                    return this.webinar.filter(e => !e.available)
                }else {
                    return this.webinar
                }
            }else {
                return null;
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    h1 {
        text-align: center;
    }

    .link-register {
        color:$mainColor;
    }

    .table-filter {
        display: flex;
        justify-content: flex-end;
    }

    .btn-form {
        margin:10px 0 10px 20px;
        border:1px solid $secondaryColor;
        background: #fff;
        color:$secondaryColor;
    }

    .btn-form.active, .btn-form:hover {
        background:$secondaryColor;
        color:#fff;
    }

    .date-display {
        min-width: 100px;
    }

    .get-link{
        cursor: pointer;
    }

    .actions-container {
        justify-content: flex-end;
    }

    .users-btn {
        background: url('./../../assets/images/users-orange.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }

    .users-btn:hover {
        background: url('./../../assets/images/users-blue.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }

    .questions-btn {
        background: url('./../../assets/images/questions-orange.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }

    .questions-btn:hover {
        background: url('./../../assets/images/questions-blue.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size:cover;
    }
</style>